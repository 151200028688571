import React from "react";
import Layout from "../components/layout";
import cls from "classnames"
import * as styles from './product.module.css'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {BLOCKS, MARKS} from "@contentful/rich-text-types";
import {GoBack} from "../components/go-back";
import SEO from "../components/seo";

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <strong>{text}</strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      return (node.data.target.fluid?.src ?
              <>
                <div>
                  <img src={node.data.target.fluid?.src} alt=""/>
                </div>
              </> : <></>
      )
    },
  },
}

const Product = (props) => {
  const info = props.pageContext.info

  return (
    <Layout location={props.location}>
      <SEO keywords={info.keywords} title={info.name}/>
      <div className="container w-75 p-5 pt-2">
        <div>
          <GoBack />
        </div>
        <div className="card border-0">
          <div className="row g-0">
            <div className={cls("col-md-4")}>
              <div className={styles.productImg}>
                <img src={info.imageLink[0].fluid.src} alt={info.imageLink[0].fluid.title} title={info.imageLink[0].fluid.title}/>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card-body pb-4 px-4 pt-0">
                <h3 className="card-title">{info.name}</h3>
                <p className={styles.price}>
                  {info.price} / <span className={styles.pieceText}>Piece</span>
                </p>
                <p className="text-muted">{info.minOrder} Piece(Min Order)</p>
                <p className="card-text">{renderRichText(info.description, options)}</p>
                <div className="pt-5">
                  <AniLink to={`/contact-us`} duration={0.3} hex="#9dc428" paintDrip>
                    <button className={styles.contactUs}>
                      Contact us
                    </button>
                  </AniLink>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            {renderRichText(info.detail, options)}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Product;
